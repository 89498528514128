import React from "react"
import { title, colon } from "./inputTitle.module.css"
const InputTitle = props => {
  const size = props.titleSize ?? 16

  const style = props.style
    ? { fontSize: size, ...props.style }
    : { fontSize: size }

  return (
    <div className={title} style={style}>
      {/* <p
        className="colon"
        style={{
          fontSize: size * 1.5,
          marginRight: size / 3,
        }}
      >
        :
      </p> */}
      <div
        className={colon}
        style={{
          fontSize: size !== 16 ? size * 2.5 + 5 : size * 2.5,
          marginRight: size !== 16 ? size - 3 : size / 2,
          bottom: size !== 16 ? size * 2.5 + 9 : size * 2.5 + 6,
        }}
      >
        <div style={{ paddingTop: "10px" }}>.</div>
        <div>.</div>
      </div>
      <div style={props.addStyleToName}>{props.name}</div>
    </div>
  )
}

export default InputTitle
