import React, {
  useCallback,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react"
import * as styles from "./fileUploadBox.module.css"
import modalClose from "images/modalClose.png"
import modalContext from "context/modalContext"
const FileUploadBox = props => {
  const { fileList, setFileList, setRemoveList } = props

  const [fileInput, onClose, fileUpload] = useFile(
    fileList,
    setFileList,
    setRemoveList
  )

  const onAttach = () => {
    fileInput.current.click()
  }

  return (
    <div className={styles.con}>
      <div className={styles.title}>
        <div>첨부파일</div>
        <div>파일은 각 10MB씩 최대 5개를 첨부할 수 있어요.</div>
      </div>
      <div className={styles.fileCon}>
        {fileList.map(file => (
          <div className={styles.file} key={file.lastModified}>
            <div>{file.name}</div>
            <img src={modalClose} onClick={() => onClose(file)} />
          </div>
        ))}
      </div>
      {props.custom ? (
        <div
          style={{
            position: "absolute",
            width: "600px",
          }}
        >
          <div
            className={`greenBtn ${styles.btn}`}
            style={{
              position: "relative",
              top: fileList.length > 1 ? `${fileList.length * 35}px` : "50px",
              ...props.btnstyle,
            }}
            onClick={onAttach}
          >
            파일 등록
          </div>
        </div>
      ) : (
        <div className={`greenBtn ${styles.btn}`} onClick={onAttach}>
          파일 첨부
        </div>
      )}
      <input type="file" ref={fileInput} multiple onChange={fileUpload} />
    </div>
  )
}

export default FileUploadBox

const useFile = (fileList, setFileList, setRemoveList) => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const fileInput = useRef(null)

  // 선택파일 삭제
  const onRemove = file => {
    setFileList(list => list.filter(listFile => !isSameFile(listFile, file)))
    if (setRemoveList && file.file_no && file.uuid) {
      setRemoveList(list => [...list, file])
    }
    fileInput.current.value = null
  }

  // input 파일 업로드
  const fileUpload = () => {
    try {
      const inputFiles = Array.from(fileInput.current.files)

      if (inputFiles.some(file => file.size > MAX.SIZE)) {
        throw new Error(`첨부파일은 각 10MB씩만 등록할 수 있습니다.`)
      } else if (fileList.length + inputFiles.length > MAX.LENGTH) {
        throw new Error(
          `첨부파일은 최대 ${MAX.LENGTH}개까지 등록할 수 있습니다.`
        )
      } else if (
        spiltList(inputFiles, vaildType, isvalidType).invalidList.length > 0
      ) {
        throw new Error("파일형식이 잘못되었습니다.")
      } else if (
        spiltList(fileList, inputFiles, isSameFile).validList.length > 0
      ) {
        throw new Error("중복된 파일은 등록할 수 없습니다.")
      }

      setFileList(files => [...files, ...inputFiles])
    } catch ({ message }) {
      setModalMain(
        <div
          className={"modalText"}
          style={{
            width: "500px",
            height: "120px",
          }}
        >
          {message}
          <div onClick={() => setmodalOpen(false)}>확인</div>
        </div>
      )
      setmodalOpen(true)
      setFileList(files => files)
    } finally {
      fileInput.current.value = null
    }
  }

  return [fileInput, onRemove, fileUpload]
}

const MAX = {
  SIZE: 10 * 1024 * 1024,
  LENGTH: 5,
}

const vaildType = [
  "application/pdf",
  "application/msword",
  "application/vnd.ms",
  "application/vnd.openxmlformats",
  "image/jpeg",
  "image/png",
]

const isSameFile = (prevFile, nextFile) => {
  if (!prevFile || !nextFile) return false

  return (
    prevFile.name === nextFile.name &&
    prevFile.size === nextFile.size &&
    prevFile.type === nextFile.type
  )
}

const isvalidType = (file, type) => {
  return file.name.toLowerCase().endsWith("hwp") || file.type.includes(type)
  // return file.name.toLowerCase().endsWith(type) || file.type.includes(type)
}

const spiltList = (
  outerList = [],
  innerList = [],
  condition = () => {
    return true
  }
) => {
  const validList = []
  const invalidList = []

  for (let i = 0; i < outerList.length; i++) {
    const isvalid = innerList.some(data => condition(outerList[i], data))

    if (isvalid) validList.push(outerList[i])
    else invalidList.push(outerList[i])
  }

  return {
    validList,
    invalidList,
  }
}
