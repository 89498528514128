import React, { useContext, useState, useRef } from "react"
import Layout from "../layout.js"
import * as styles from "./request.module.css"
import File from "components/tools/file"
import InputTitle from "components/tools/InputTitle.js"
import CheckBox from "components/tools/checkBox.js"
import api from "apis"
import { faq } from "utils/data"
import { textBox, textBoxErr } from "components/tools/file.module.css"
import modalContext from "context/modalContext"
import { navigate } from "gatsby-link"
import FileUploadBox from "components/tools/fileUploadBox.js"
const Request = () => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const [checkBoxNotClicked, setCheckBoxNotClicked] = useState(false)
  const [fileList, setFileList] = useState([])

  const currentCheck = useRef(null)
  const onCheck = ({ target }) => {
    const typeCheckBox = document.querySelectorAll(".circleCheckBox")
    typeCheckBox.forEach(check => {
      check.checked = false
    })

    target.checked = true
    currentCheck.current = target
    setCheckBoxNotClicked(false)
  }

  const onContact = async () => {
    const [{ value: question_body, classList }] =
      document.getElementsByName("introduce")

    const [{ value: question_title }, errMsg] =
      document.getElementsByName("titleInput")

    console.log(currentCheck.current)
    const question_category = currentCheck.current?.id ?? 0

    if (question_category === 0) {
      setCheckBoxNotClicked(true)
    }

    console.log(question_category)

    if (question_body) {
      classList.replace(textBoxErr, textBox)
    } else {
      classList.replace(textBox, textBoxErr)
      alert("내용을 입력해주세요.")
    }

    if (question_title) {
      errMsg.textContent = ""
    } else {
      errMsg.textContent = "필수 항목입니다."
      return
    }

    console.log(fileList)
    const formdata = new FormData()

    for (let i = 0; i < fileList.length; i++) {
      formdata.append("data_attach", fileList[i])
    }

    formdata.append("question_category", question_category)
    formdata.append("question_title", question_title)
    formdata.append("question_body", question_body)

    console.log(question_body)

    const result = await api.contact({
      body: formdata,
    })

    if (result) {
      //성공
      setmodalOpen(true)

      setModalMain(
        <div className={"modalText"}>
          등록되었습니다.
          <div
            onClick={() => {
              setmodalOpen(false)
              navigate("/cs/contact/list")
            }}
          >
            확인
          </div>
        </div>
      )
    }
    // else {
    //   setmodalOpen(true)
    //   setModalMain(
    //     <div className={"modalText"}>
    //       잠시후 다시 시도해주세요.
    //       <div
    //         onClick={() => {
    //           setmodalOpen(false)
    //         }}
    //       >
    //         확인
    //       </div>
    //     </div>
    //   )
    // }
  }

  return (
    <Layout customStyle={{ display: "none" }}>
      <div className={styles.title}>1:1 문의하기</div>
      <div className={styles.formCon}>
        <div>
          <InputTitle
            name={"유형"}
            titleSize={16}
            style={{ fontFamily: "Noto Sans", lineHeight: 1.4, marginTop: 5 }}
          />
          <div className={styles.checkList}>
            {Array.from(faq).map(([key, value]) => (
              <div key={key}>
                <CheckBox
                  id={key}
                  className={"circleCheckBox"}
                  onClick={onCheck}
                />
                <div>{value}</div>
              </div>
            ))}
          </div>
        </div>
        {checkBoxNotClicked ? (
          <div
            style={{ fontSize: "13px", color: "#e95c63", marginLeft: "67px" }}
          >
            유형을 선택해주세요
          </div>
        ) : null}

        <div>
          <InputTitle
            name={"제목"}
            titleSize={16}
            style={{
              marginTop: "12px",
              marginBottom: 10,
              fontFamily: "Noto Sans",
              lineHeight: 1.4,
            }}
          />
          <div className={styles.titleInput}>
            <input
              type="text"
              placeholder={"제목을 입력하세요."}
              name={"titleInput"}
            />
            <div name={"titleInput"} className={"errMsg"}></div>
          </div>
        </div>
        {/* <File placeholder={"문의사항을 작성해주세요."} /> */}
        <textarea
          className={styles.textBox}
          name="introduce"
          placeholder="  문의내용을 최대한 자세히 써주시면 정확한 답변을 받으실 수 있습니다.
          1. OS종류 : windows / mac
          2. 디바이스 : pc / 모바일 / 태블릿
          3. 자세한 문의 내용"
        />
        <div className={styles.filesList}>
          <FileUploadBox
            fileList={fileList}
            setFileList={setFileList}
            custom
            btnstyle={{ left: "100px" }}
          />
        </div>
      </div>
      <div
        className="longBtn"
        style={{ margin: "90px auto 0 " }}
        onClick={onContact}
      >
        1:1 문의하기
      </div>
    </Layout>
  )
}

export default Request
